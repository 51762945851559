<template>
  <div v-if="props">
    <div class="modal-box" :style="{padding:props.boxPaddingH+'px '+props.boxPaddingV+'px',background: props.boxBgColor}">
      <div v-if="props.children&&props.children.length">
        <!-- 一行一个 -->
        <div v-if="props.type===0">
          <div v-for="(item,index) in props.children" :key="index" :style="{marginBottom: props.itemMargin+'px'}" class="img-box">
            <img v-if="item.imgUrl&&!item.imgError" :alt="props.title" :src="baseUrl + item.imgUrl" class="add-img" @error="item.imgError=true" :style="{borderRadius: props.isRound?'8px':'',height: props.height/100+'rem',background: props.boxBgColor}" />
            <div v-else class="add-img" :style="{height: props.height/100+'rem'}">
              <icon-svg class="default-box" icon-class="img-default" />
            </div>
            <p class="img-title" :class='{round:props.isRound}' v-if="item.title" :style='{backgroundColor:props.bgColor,color:props.textColor,fontSize:props.fontSize+"px"}'>{{item.title}}</p>
          </div>
        </div>
        <!-- 轮播 -->
        <div v-if="props.type===1">
          <swiper ref="swiperRef" class="img-swiper" :options="swiperOptions">
            <swiper-slide v-for="(item,index) in props.children" :key="index" class="swiper-no-swiping">
              <div class="img-box" :style="{marginBottom: props.itemMargin+'px'}">
                <img v-if="item.imgUrl&&!item.imgError" :alt="item.title" :src="baseUrl + item.imgUrl" class="add-img" :style="{borderRadius: props.isRound?'8px':'',height: props.height/100+'rem',background: props.boxBgColor}" @error="item.imgError= true" style='object-fit: cover;' />
                <div v-else class="add-img" :style="{height: props.height/100+'rem'}">
                  <icon-svg class="default-box" icon-class="img-default" />
                </div>
                <p class="img-title" :class='{round:props.isRound}' v-if="item.title" :style='{backgroundColor:props.bgColor,color:props.textColor,fontSize:props.fontSize+"px"}'>{{item.title}}</p>
              </div>
            </swiper-slide>
            <div class="swiper-pagination"></div>
          </swiper>
        </div>
        <!-- 滚动 -->
        <div v-if="props.type === 2" style="overflow:auto;width:100%;">
          <div style="display:flex;width:auto">
            <div v-for="(item,index) in props.children" :key="index" class='img-box' :style="{marginRight: props.itemMargin+'px'}">
              <img v-if="item.imgUrl&&!item.imgError" :alt="props.title" :src="baseUrl + item.imgUrl" class="add-img-h" @error="item.imgError = true" :style="{borderRadius: props.isRound?'8px':'',height: props.height/100+'rem',background: props.boxBgColor}" />
              <div v-else class="add-img-h" :style="{height: props.height/100+'rem'}">
                <icon-svg class="default-box" icon-class="img-default" />
              </div>
              <p class="img-title" :class='{round:props.isRound}' v-if="item.title" :style='{backgroundColor:props.bgColor,color:props.textColor,fontSize:props.fontSize+"px"}'>{{item.title}}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="add-img" style='min-height:3.5rem'>
        <icon-svg class="default-box" icon-class="img-default" />
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from '@vue/composition-api'
export default {
  name: 'add-img',
  props: {
    props: {
      type: Object
    }
  },

  setup (props, { root, refs }) {
    const state = reactive({
      swiperOptions: {
        observer: true,
        observerParents: true,
        observeSlideChildren: true,
        autoplay: {
          disableOnInteraction: false
        }
      },
      swiperRef: null,
      baseUrl: process.env.VUE_APP_IMG
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
.add-img {
  width: 100%;
  background: #eee;
  display: block;
  object-fit: cover;
  line-height: 3.5rem;
  text-align: center;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.add-img-h {
  width: auto;
  height: 3.5rem;
  background: #eee;
  display: block;
  object-fit: cover;
  line-height: 3.5rem;
  text-align: center;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.img-title {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 6px 16px;
  box-sizing: border-box;
  line-height: 1em;
  text-align: center;
  font-size: 16px;
}
.img-title.round {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.img-box {
  position: relative;
}
.img-box:last-child {
  margin: 0 !important;
}
.default-box {
  font-size: 80px;
  display: block;
  width: 100%;
}
</style>
